<template>
    <div class="pay-result">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText">支付结果</span>
            <span
                v-if="alipayPayModel.resultType==30"
                @click="changeReceivablesCode"
                slot="rightBtn"
            >
                <Svgs color="#000" name="iconerweima"></Svgs>
            </span>
        </Header>
        <div>
            <!--形态
                10-支付中
                20-重新支付
                30-支付成功,但金额超
                40-支付成功,可上传发票
                50-重新结算
            -->
            <ScrollBar
                class="opOrderListScroll"
                @onTop="loadTop"
                :loadType="loadType"
            >
                <div slot="scrollBar">
                    <div v-if="alipayPayModel.resultType==10">
                        <div class="toPayAlipay">
                            <div class="aliImg">
                                <img src="../../assets/img/alipayIcon.png" alt />
                            </div>
                            <div class="inPay">支付中</div>
                            <div class="toPayAlipayName">{{alipayPayModel.shopName}}</div>
                            <div class="toPayAlipayMoney">
                                <span>¥{{alipayPayModel.settleAmount}}</span>
                            </div>
                            <div class="toPayAlipayFoot">订单正在支付,请稍等...</div>
                        </div>
                    </div>
                    <div v-else-if="alipayPayModel.resultType==20">
                        <div class="toPayAlipay">
                            <div class="aliImg">
                                <Svgs color="#FC3258" name="iconshanchu1"></Svgs>
                            </div>
                            <div class="filed">支付失败</div>
                            <div class="toPayAlipayName">{{alipayPayModel.shopName}}</div>
                            <div class="toPayAlipayMoney">
                                <span>¥{{alipayPayModel.settleAmount}}</span>
                            </div>
                            <div class="toPayAlipayFoot">
                                支付超时，建议联系
                                <span
                                    class="call"
                                    @click="ihtml.telCall('01056304858')"
                                >支付中心</span>寻求帮助!
                            </div>
                        </div>
                    </div>
                    <div v-else-if="alipayPayModel.resultType==30">
                        <div class="toPayAlipay">
                            <div class="aliImg">
                                <Svgs color="#4AC672" name="iconsuccess1"></Svgs>
                            </div>
                            <div class="success">支付成功</div>
                            <div class="toPayAlipayName">{{alipayPayModel.shopName}}</div>
                            <div class="toPayAlipayMoney">
                                <span>¥{{alipayPayModel.payAmount}}</span>
                            </div>
                            <div class="toPayAlipayFoot">{{alipayPayModel.message}}</div>
                            <div class="toPayAlipayRow">
                                <Btn btnClass="help" @callBack="ihtml.telCall('01056304858')">
                                    <span slot="btnContent">寻求帮助</span>
                                </Btn>
                                <Btn btnClass="submit" @callBack="next">
                                    <span slot="btnContent">确认</span>
                                </Btn>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="alipayPayModel.resultType==40">
                        <div class="toPayAlipay">
                            <div class="aliImg">
                                <Svgs color="#4AC672" name="iconsuccess1"></Svgs>
                            </div>
                            <div class="success">支付成功</div>
                            <div class="toPayAlipayName">{{alipayPayModel.shopName}}</div>
                            <div class="toPayAlipayMoney">
                                <span>¥{{alipayPayModel.payAmount}}</span>
                            </div>
                            <Btn btnClass="sure" @callBack="next">
                                <span slot="btnContent">完成支付</span>
                            </Btn>
                        </div>
                    </div>
                    <div v-else-if="alipayPayModel.resultType==50">
                        <div class="toPayAlipay">
                            <div class="aliImg">
                                <Svgs color="#FC3258" name="iconshanchu1"></Svgs>
                            </div>
                            <div class="filed">支付失败</div>
                            <div class="toPayAlipayName">{{alipayPayModel.shopName}}</div>
                            <div class="toPayAlipayMoney">
                                <span>¥{{alipayPayModel.settleAmount}}</span>
                            </div>
                            <div class="toPayAlipayFoot">
                                支付超时，建议联系支付中心寻求帮助!
                                <span class="call">
                                    支付遇到问题,请联系
                                    <span
                                        @click="ihtml.telCall('01056304858')"
                                    >010-8045-5787</span>
                                </span>
                            </div>
                            <Btn btnClass="sure" @callBack="closeResultDialog">
                                <span slot="btnContent">请重新发起支付</span>
                            </Btn>
                        </div>
                    </div>
                </div>
            </ScrollBar>
            <QrCode ref="codePop" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import QrCode from "./QrCode.vue";
import { miceService } from "@/service/miceService.js";
import {
	SmartStorage
} from 'smart-core-util'
export default {
    data() {
        return {
            alipayPayModel: {}, //{"resultType":10,},
            alipayStatus: null,
            kouBeiHub: null,
            lookFlag: false,
            loadType: ''
        };
    },
    components: {
        QrCode
    },
    computed: {
        ...mapGetters(["expenseId", "orderDetail"])
    },
    created() {
        // this.nativeRefresh(false);
        window.fluttercallBack = this.fluttercallBack;
        this.openSignal();
        this.addOnResumeEvent();
    },
    methods: {
        // nativeRefresh(status) {
        //     if (status) {
        //         status = "true";
        //     } else {
        //         status = "false";
        //     }
        //     var options = {
        //         loadUrl: "",
        //         method: "SMGeneral.setNativeRefreshable",
        //         postData: status
        //     };
        //     this.iJsBridge.call(options);
        // },
        openSignal() {
            var _this = this;
            console.log("signalr");
            this.kouBeiHub = new this.iSignalr({
                hubName: "kouBeiHub",
                queryParams: "group=" + this.expenseId,
                listeners: [
                    {
                        methodName: "voucherChanged",
                        method: function(data) {
                            console.log(data);
                            _this.on_voucherChanged(data);
                        }
                    },
                    {
                        methodName: "paidOverConfirm",
                        method: function(data) {
                            console.log(data);
                            _this.on_paidOverConfirm(data);
                        }
                    },
                    {
                        methodName: "reset",
                        method: function(data) {
                            console.log(data);
                            _this.on_reset(data);
                        }
                    }
                ],
                host: SmartStorage.get("Uris").Uris.signalr
            });
            this.kouBeiHub.install().then(() => {
                this.reload();
            });
        },
        addOnResumeEvent() {
            var hasOpenSignal = false;
            // var options = {
            //     loadUrl: "",
            //     method: "SMGeneral.addOnResumeEvent",
            //     postData: "",
            //     callback: () => {
            //         if (hasOpenSignal) return;
            //         hasOpenSignal = true;
            //         this.openSignal();
            //     }
            // };
            // this.iJsBridge.call(options);
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("App.addLifeCycleJs", 'fluttercallBack');
            } else {
                var options = {
                    loadUrl: '',
                    method: 'SMGeneral.addOnResumeEvent',
                    postData: '',
                    callback: () => {
                        if (hasOpenSignal) return;
                        hasOpenSignal = true;
                        this.openSignal();
                    }
                }
                this.iJsBridge.call(options)
            }
        },
        removeOnResumeEvent() {
            // var options = {
            //     loadUrl: "",
            //     method: "SMGeneral.removeOnResumeEvent",
            //     postData: "",
            //     callback: () => {}
            // };
            // this.iJsBridge.call(options);
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler("App.removeLifeCycleJs");
            } else {
                var options = {
                    loadUrl: '',
                    method: 'SMGeneral.removeOnResumeEvent',
                    postData: '',
                    callback: () => {}
                }
                this.iJsBridge.call(options)
            }
        },
        fluttercallBack(stateString){
            if(stateString == 'resumed'){
                this.openSignal();
            }
        },
        back() {
            // this.nativeRefresh(true);
            this.removeOnResumeEvent();
            this.unInstallKouBeiHub();
            this.$router.push({ path: "/orderList" });
        },
        //打开关闭收款码
        changeReceivablesCode(type) {
            this.$refs.codePop.openPop();
        },
        async loadTop() {
            this.loadType='top';
            await this.openSignal();
            setTimeout(() => {
                this.loadType='';
            }, 1000)
        },
        reload() {
            var param = {
                expenseId: this.expenseId
            };
            miceService.triggerPaySignal(param).then(res => {
                if (res && res.success) {
                    console.log("完成");
                }
            });
        },
        on_voucherChanged(data) {
            this.alipayPayModel = data;
        },
        on_paidOverConfirm(data) {
            this.alipayPayModel = data;
        },
        on_reset(data) {
            this.alipayPayModel = data;
        },
        //跳转到上传发票
        next() {
            var orderDetail = this.orderDetail;
            if (orderDetail && orderDetail.payStatus == 29) {
                let params = {
                    expenseId: this.expenseId,
                    proposalId: orderDetail.proposalId
                };
                miceService.kouBeiConfirmSettle(params).then(res => {
                    if (res && res.success) {
                        this.actionDirective();
                    } else {
                        this.toast("确认账单失败");
                    }
                });
            } else {
                this.actionDirective();
            }
        },
        actionDirective() {
            this.unInstallKouBeiHub();
            this.$router.push({ path: "/uploadInvoice" });
        },
        closeResultDialog() {
            this.unInstallKouBeiHub();
            this.$router.push({
                path: "/"
            });
        },
        unInstallKouBeiHub() {
            if (this.kouBeiHub) {
                this.kouBeiHub.uninstall();
            }
        }
    }
};
</script>
<style lang="scss">

.pay-result {
    .toPayAlipay {
        .opOrderListScroll{
            height: 5rem;
            overflow-y: scroll;
            padding: .25rem .25rem 0 .25rem;
        }
        .aliImg {
            margin-top: 0.4rem;
            margin-bottom: 0.17rem;
            img {
                width: 0.8rem;
            }
            .icon {
                width: 0.8rem;
                height: 0.8rem;
            }
        }
        .inPay {
            font-size: 0.16rem;
            color:  var(--themeColor);
        }
        .filed {
            font-size: 0.16rem;
            color: #fc3258;
        }
        .success {
            font-size: 0.16rem;
            color: #4ac672;
        }
        .toPayAlipayName {
            color: #13161b;
            font-size: 0.14rem;
            margin: 0.1rem 0 0.3rem 0;
        }
        .toPayAlipayMoney {
            font-size: 0.2rem;
            color: #13161b;
        }
        .toPayAlipayFoot {
            color: #c0c4cd;
            font-size: 0.14rem;
            margin: 0.3rem 0.25rem 0 0.25rem;
        }
        .call {
            color:  var(--themeColor);
        }
        .toPayAlipayRow {
            padding: 0 0.25rem;
            display: flex;
            justify-content: space-between;
            margin-top: 0.3rem;

            .main {
                height: 0.5rem;
                line-height: 0.5rem;
                border-radius: 0.25rem;
                width: 1.5rem;
            }
        }
        .sure {
            height: 0.5rem;
            line-height: 0.5rem;
            border-radius: 0.25rem;
            margin: 0.3rem 0.25rem 0 0.25rem;
        }
    }
}
</style>


