<template>
    <div class="code-pop">
        <Pop :clickClose="true" ref="pops">
            <div slot="popContent">
                <div class="code-content">
                    <img src="../../assets/img/receivablesCode.png" />

                    <Btn btnClass="code-close" @callBack="openPop">
                        <span slot="btnContent">关闭</span>
                    </Btn>
                </div>
            </div>
        </Pop>
    </div>
</template>
<script>
export default {
    name: "QrCode",
    methods: {
        openPop() {
            this.$refs.pops.changePop();
        }
    }
};
</script>
<style lang="scss">
.code-pop {
    .code-close {
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.25rem;
        margin-top: 0.3rem;
    }
    .popContent {
        top: 15%;
    }
    .code-content {
        background: #fff;
        padding: 0.25rem;
        width: 70%;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}
</style>